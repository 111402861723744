import { useEffect, useState } from "react";
import {
    connectWallet, createSHOAP,
    getCurrentWalletConnected
} from "./util/interact.js";
import FileUploader from "./FileUploader";
import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";
import Select from 'react-select';

const states = {
    create: "create",
    pending: "pending",
    cancelled: "cancelled",
    success: "success",
    error: "error"
}

const shoapContracts = [
    { value: process.env.REACT_APP_SHOAP_ADDRESS, label: 'TRU' },
    { value: process.env.REACT_APP_SHOAP_ADDRESS_AMBIE, label: 'AMBIE' }
];

const Shoap = (props) => {
    const [status, setStatus] = useState("");

    const [file, setFile] = useState(undefined);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [additionalProps, setAdditionalProps] = useState("");
    const [shoapLocation, setShoapLocation] = useState("TRU Band Room");
    const [eventName, setEventName] = useState("");
    const [artBy, setArtBy] = useState("");
    const [performer, setPerformer] = useState("");
    const [maxSupply, setMaxSupply] = useState(0);
    const [tokenId, setTokenId] = useState(0);
    const [shoapDate, setShoapDate] = useState(new Date());
    const [valid, setValid] = useState(false);
    const [createDisabled, setCreateDisabled] = useState(false);
    const [url, setUrl] = useState("https://party.rockinguniquehorns.com");
    const [signedMessage, setSignedMessage] = useState("");
    const [shoapContractAddress, setShoapContractAddress] = useState(shoapContracts[0]);

    const [currentState, setCurrentState] = useState(states.create)


    useEffect(async () => {
        setStatus(status);
        checkValidity()
    }, []);

    useEffect(async () => {
        checkValidity()
    }, [file, name, description, maxSupply]);


    function startOver() {
        resetState()
    }

    function showForm() {
        setCurrentState(states.create)
        checkValidity()
        setCreateDisabled(false)
    }

    function checkValidity() {
        setValid(file && name && description && maxSupply)
    }

    function resetState() {
        setCurrentState(states.create)
        setFile(undefined)
        setName("")
        setDescription("")
        setMaxSupply(0)
        setTokenId(0)
        setValid(false)
        setShoapDate(new Date())
        setShoapLocation("")
        setEventName("")
        setArtBy("")
        setPerformer("")
        setAdditionalProps("")
        setCreateDisabled(false)
    }

    const onFileChanged = async(newFile) => {
        setFile(newFile)
    }

    const handleContractChange = (selectedOption) => {
        this.setState({ selectedOption }, () =>
            console.log(`Option selected:`, this.state.selectedOption)
        );
    };

    const onCreatePressed = async() => {
        setCurrentState(states.pending)
        setCreateDisabled(true)

        const attributes = []
        if(shoapDate) {
            attributes.push({
                display_type: 'date',
                trait_type: 'Event Date',
                value: shoapDate.getTime()
            })
        }
        if(eventName) {
            attributes.push({
                trait_type: 'Event',
                value: eventName
            })
        }
        if(artBy) {
            attributes.push({
                trait_type: 'Art By',
                value: artBy
            })
        }
        if(performer) {
            attributes.push({
                trait_type: 'Performer',
                value: performer
            })
        }
        if(shoapLocation) {
            attributes.push({
                trait_type: 'Location',
                value: shoapLocation
            })
        }
        if(additionalProps) {
            const ap = JSON.parse(additionalProps)
            attributes.concat(ap)
        }

        const metadata = {
            description: description,
            name: name,
            external_url: url
        }

        if(attributes.length > 0) {
            metadata.attributes = attributes
        }

        const uploadResponse = await createSHOAP(shoapContractAddress.value,props.walletAddress, name, file, metadata, maxSupply)
        if(uploadResponse.success) {
            console.log("Successfully created shoap -> " + uploadResponse.shoapId)
            setTokenId(uploadResponse.shoapId)
            setCurrentState(states.success)
            setStatus(uploadResponse.status)
        }
        else {
            setCurrentState(states.error)
            setCreateDisabled(false)
            setStatus(uploadResponse.status)
        }
    }

    return (
        <div className="Minter">

            <br></br>
            {currentState === states.create && <div className="content">
                <h1 id="title">Create SHOAP</h1>
                <Select
                    className="select-contract"
                    defaultValue={shoapContractAddress}
                    onChange={setShoapContractAddress}
                    options={shoapContracts}
                />
                <p>
                    Add your Image, name, description, max supply and the optional additional properties, then press "Create SHOAP"
                </p>
                <form className="horizontal">
                    <div className="span-all">
                        <FileUploader onChange={onFileChanged}/>
                    </div>
                    <h2>Name* </h2>
                    <input
                        type="text"
                        placeholder="e.g. Cupcake"
                        onChange={(event) => setName(event.target.value)}
                        value={name}
                    />
                    <h2>Description* </h2>
                    <textarea onChange={(event) => setDescription(event.target.value)} value={description}></textarea>
                    <h2>Max Supply* </h2>
                    <input
                        type="number"
                        placeholder="e.g. 500"
                        onChange={(event) => setMaxSupply(event.target.value)}
                        value={maxSupply}
                    />
                    <h2>Event Name </h2>
                    <input
                        type="text"
                        placeholder="e.g. POI Star Party"
                        onChange={(event) => setEventName(event.target.value)}
                        value={eventName}
                    />
                    <h2>Art By </h2>
                    <input
                        type="text"
                        placeholder="e.g. Roustan"
                        onChange={(event) => setArtBy(event.target.value)}
                        value={artBy}
                    />
                    <h2>Performer </h2>
                    <input
                        type="text"
                        placeholder="e.g. Mr.Swe"
                        onChange={(event) => setPerformer(event.target.value)}
                        value={performer}
                    />
                    <h2>Location </h2>
                    <input
                        type="text"
                        placeholder="e.g. TRU Band Room"
                        onChange={(event) => setShoapLocation(event.target.value)}
                        value={shoapLocation}
                    />
                    <h2>Event Date </h2>
                    <DatePicker selected={shoapDate} onChange={(date) => setShoapDate(date)} />
                    <h2>✍️ Additional Properties </h2>
                    <textarea onChange={(event) => setAdditionalProps(event.target.value)} value={additionalProps}></textarea>
                </form>
                <button className="btn primary" onClick={onCreatePressed} disabled={createDisabled || !valid}>
                    Create SHOAP
                </button>
            </div>}
            {currentState === states.pending && <div className="content pending">
                <h2>Creating SHOAP</h2>
                <div className="note">Please stand by...</div>
            </div>}
            {currentState === states.error && <div className="content pending">
                <h2>Error when creating SHOAP</h2>
                <div className="note">{status}</div>
                <button className="btn primary" onClick={showForm}>Try again</button>
            </div>}
            {currentState === states.success && <div className="content success">
                <h2>SHOAP created</h2>
                <div className="shoap-token-id">{tokenId}</div>
                <div className="note">You can now copy this id to the event and close this window</div>

                <button className="btn primary" onClick={startOver}>Create new SHOAP</button>
            </div>}
        </div>
    );
};

export default Shoap;
