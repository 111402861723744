import React, { useCallback, useState } from 'react';
import {useDropzone} from 'react-dropzone';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginRight: 8,
    width: 180,
    height: 180,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const FileUploader = ({onChange}) => {

    const [file, setFile] = useState([]);

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles);
        //setFile(acceptedFiles[0])
        setFile(Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0])
        }));
        onChange(acceptedFiles[0])
    }, []);

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        onDrop,
        maxFiles: 1
    });

    return (
        <section className="image-uploader">
            <div style={thumbsContainer}>
                {file && <div style={thumb} key={file.name}>
                    <div style={thumbInner}>
                        <img
                            src={file.preview}
                            style={img}
                        />
                    </div>
                </div>}
            </div>
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop the image here, or click to select</p>
            </div>
        </section>
    );
}

export default FileUploader;