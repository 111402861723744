import './scss/main.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Shoap from './Shoap'
import Rewarder from "./Rewarder";
import {useEffect, useState} from "react";
import {connectWallet, getCurrentWalletConnected} from "./util/interact";

function App() {

    const [status, setStatus] = useState("");
    const [walletAddress, setWallet] = useState("");

    useEffect(async () => {
        const { address, status } = await getCurrentWalletConnected();

        setWallet(address);

        addWalletListener();
    }, []);



    const connectWalletPressed = async () => {
        const walletResponse = await connectWallet();
        setStatus(walletResponse.status);
        setWallet(walletResponse.address);
    };

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                } else {
                    setWallet("");
                    setStatus("🦊 Connect to Metamask.");
                }
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
                    </a>
                </p>
            );
        }
    }

  return (
      <Router>
          <div className="App">
              <nav>
                  {/*
                  <ul>
                      <li>
                          <Link to="/">SHOAP</Link>
                      </li>
                      <li>
                          <Link to="/rewarder">TRU Rewarder</Link>
                      </li>
                  </ul>
                    */}
                  <button className="btn" id="walletButton" onClick={connectWalletPressed}>
                      {walletAddress.length > 0 ? (
                          "Connected: " +
                          String(walletAddress).substring(0, 6) +
                          "..." +
                          String(walletAddress).substring(38)
                      ) : (
                          <span>Connect Wallet</span>
                      )}
                  </button>
                  {status && <div className="status">{status}</div>}
              </nav>

              {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
              <div className="main">
                  <Switch>
                      <Route path="/rewarder">
                          <Rewarder />
                      </Route>
                      <Route path="/">
                          <Shoap walletAddress={walletAddress}/>
                      </Route>
                  </Switch>
              </div>
          </div>
      </Router>
  );
}

export default App;
