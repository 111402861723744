import {pinFileToIPFS, pinJSONToIPFS} from "./pinata.js";
import {uploadMedia, uploadMetadata} from "./uploader";
import {ethers} from "ethers";
require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const apiUrl = process.env.REACT_APP_API_URL;
const rewarderContractABI = require("../contracts/TRURewarder.json");
const shoapContractABI = require("../contracts/ShowAttendanceToken.json");
const rewarderContractAddress = "0x30bB2D1Cac7BBED9D8127590Ce83bc479192811c";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        address: addressArray[0]
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noreferrer"  href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const loadGuestbook = async (eventId) => {
  const url = apiUrl + "/guestbook/" + eventId;
  fetch(url)
      .then(res => res.json())
      .then((data) => {
        return data;
      })
}

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0]
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noreferrer"  href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

async function loadShoapContract(shoapContractAddress) {
  return new web3.eth.Contract(shoapContractABI, shoapContractAddress);
}
async function loadRewarderContract() {
  return new web3.eth.Contract(rewarderContractABI, rewarderContractAddress);
}

export const sendTokens = async (tokenContractAddress, tokenId, receiversAddresses) => {

}

export const uploadFile = async (signature, file) => {
  console.log("Uploading file", file)
  const pinataResponse = await uploadMedia(signature, file);
  console.log("File upload response", pinataResponse)
  if (!pinataResponse.success) {
    return {
      success: false,
      status: pinataResponse.message
    };
  }
  return {
    success: true,
    status: "Pinata upload successful",
    url: pinataResponse.pinataUrl,
    ipfs: pinataResponse.ipfsUrl,
    hash: pinataResponse.hash,
  }

}

export const uploadMd = async (signature, metadata) => {
  console.log("Uploading metadata", metadata)
  const pinataResponse = await uploadMetadata(signature, metadata);
  console.log("Metadata upload response", pinataResponse)
  if (!pinataResponse.success) {
    return {
      success: false,
      status: pinataResponse.message
    };
  }
  return {
    success: true,
    status: "Pinata upload successful",
    url: pinataResponse.pinataUrl,
    ipfs: pinataResponse.ipfsUrl,
    hash: pinataResponse.hash,
  }

}

export const signMessage = async (walletAddress, message) => {
  // Sign the string message
  //let hash = web3.utils.sha3(message)
  //let flatSig = await web3.eth.sign(web3.utils.sha3(message), walletAddress)

  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const flatSig = await signer.signMessage(message)

  console.log(flatSig)

  // For Solidity, we need the expanded-format of a signature
  // let sig = ethers.utils.splitSignature(flatSig);

  // Call the verifyString function
  //let recovered = await contract.verifyString(message, sig.v, sig.r, sig.s);

  // console.log(recovered);
  return flatSig
}

export const createSHOAP = async (shoapContractAddress, walletAddress, name, file, metadata, maxSupply) => {

  let sig = await signMessage(walletAddress, "It's a me, Mario!")

  if (maxSupply < 1 || !file || !metadata ) {
    return {
      success: false,
      status: "❗Please make sure all fields are completed before creating the SHOAP.",
    };
  }

  const pinataFileResponse = await uploadFile(sig, file)
  if (!pinataFileResponse.success) {
    return {
      success: false,
      status: "😢 " + pinataFileResponse.status
    }
  }

  metadata.image = pinataFileResponse.ipfs

  const pinataMetadataResponse = await uploadMd(sig, metadata)
  if (!pinataMetadataResponse.success) {
    return {
      success: false,
      status: "😢 " + pinataFileResponse.status
    }
  }

  const contract = await loadShoapContract(shoapContractAddress);

  // function createShoap(uint256 _tokenId, string memory _name, string memory _url, uint256 _maxSupply)

  const tokenId = await contract.methods.getNextTokenId().call();

  const transactionParameters = {
    to: shoapContractAddress,
    from: window.ethereum.selectedAddress,
    data: contract.methods
      .createShoap(tokenId, name, pinataMetadataResponse.ipfs, maxSupply)
      .encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });

    return {
      success: true,
      shoapId: tokenId,
      status: "✅ Created SHOAP with id: " + tokenId + " Transaction hash: " + txHash
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};
