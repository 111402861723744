require('dotenv').config();
const axios = require('axios');

const apiUrl = process.env.REACT_APP_API_URL;

export const uploadMetadata = async(signature, json) => {
    const url = apiUrl + "/shoap/metadata";
    return axios
        .post(url, json, {
            headers: {
                "X-Auth" : signature
            }
        })
        .then(function (response) {
           return response.data
        })
        .catch(function (error) {
            console.log(error)
            return {
                success: false,
                message: error.message,
            }
        });
};
export const uploadMedia = async(signature, file) => {
    const url = apiUrl + "/shoap/media";
    const formData = new FormData()
    formData.append("file", file)
    return axios
        .post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Auth' : signature
            }
        })
        .then(function (response) {
           return response.data
        })
        .catch(function (error) {
            console.log(error)
            return {
                success: false,
                message: error.message,
            }

        });
};