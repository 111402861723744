import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT, sendTokens, loadGuestbook
} from "./util/interact.js";

const apiUrl = process.env.REACT_APP_API_URL;

const Rewarder = (props) => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");

  const [eventId, setEventId] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [tokenContractAddress, setTokenContractAddress] = useState("");
  const [receiverAddresses, setReceiverAddresses] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [guestbookEntries, setGuestbookEntries] = useState([]);

  useEffect( () => {
    async function lfg() {
      const {address, status} = await getCurrentWalletConnected();

      setWallet(address);
      setStatus(status);

      addWalletListener();
    }
    lfg();
  }, []);

  useEffect(() => {
    if(eventId !== "") {
      console.log("Selected event: " + eventId)
      const url = apiUrl + "/guestbook/" + eventId;
      fetch(url)
          .then(res => res.json())
          .then((entries) => {
            let se = {}
            if (entries.length) {
              se.id = entries[0].eventId
              se.name = entries[0].eventName
              se.date = entries[0].eventDate
            }
            setSelectedEvent(se)
            setGuestbookEntries(entries)
            setReceiverAddresses(entries.map(entry => entry.address))
          })
    }
  }, [eventId]);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" rel="noreferrer"  href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => {
    const { success, status } = await sendTokens(tokenContractAddress, tokenId, receiverAddresses);
    setStatus(status);
    if (success) {
      console.log("Successfully sent tokens...")
    }
  };

  return (
    <div className="Minter">
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>

      <br></br>
      <h1 id="title">🧙‍ TRU Rewarder</h1>
      <p>
          Add the event id, the ERC-1155 token contract address and it's id to send it to the addresses on the guestbook. <br/>
          VIPs of that event (TRU holders + possibly additional Token holders) will always receive a reward. <br/>
          For non-VIP's you can specify a number between (including) 0 (=none) and 1 (=everyone) to specify the percentage of winners that should also receive a reward.
      </p>
      <form>
        <h2>🎉 Event Id </h2>
        <input
          type="text"
          placeholder="e.g. recmS6PmX9g1xwhK4"
          onChange={(event) => setEventId(event.target.value)}
        />
        <h2>📝 ERC-1155 contract address: </h2>
        <input
          type="text"
          placeholder="e.g. 0x2953399124F0cBB46d2CbACD8A89cF0599974963"
          onChange={(event) => setTokenContractAddress(event.target.value)}
        />
        <h2>✍️ Token Id: </h2>
        <input
          type="number"
          placeholder="e.g. 44753157085617995561496434280427361145189192983866890870649936288730518127592"
          onChange={(event) => setTokenId(event.target.value)}
        />
        <h2>🎟️ Winning percentage: </h2>
        <input
          type="number"
          placeholder="e.g. 44753157085617995561496434280427361145189192983866890870649936288730518127592"
          onChange={(event) => setTokenId(event.target.value)}
          value={0.2}
        />
      </form>
      <div className="event-info">
          <h2>Event Name: {selectedEvent.name}</h2>
          <div className="event-date"> Date: {selectedEvent.date}</div>
          <div className="guestbook-info">
              <div className="total">Total Guestbook entries: {guestbookEntries.length}</div>
              <div className="winners">Total VIPs: {guestbookEntries.filter( entry => entry.vip === "yes").length}</div>
              <div className="winners">Total Winners (including VIP): {guestbookEntries.filter( entry => entry.winner === "yes").length}</div>
          </div>
      </div>
      <button id="mintButton" onClick={onMintPressed}>
        Send
      </button>
      <p id="status" style={{ color: "red" }}>
        {status}
      </p>
    </div>
  );
};

export default Rewarder;
